body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.gif-container {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 20px;
  @media (max-width:767px){
   flex-direction: column;
  }
}

.gif {
  width: 100%;
  max-width: 330px;
  padding: 10px ;  
}

.footer-links a {
  color: white;
  
  text-decoration: none;
}
.footer-links a:hover {
  color: rgb(203, 201, 201);
}